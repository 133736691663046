import React from "react"
import Layout from "../components/layout.js"
import Meta from "../components/meta.js"
export default function Discord() {
  return (
    <Layout>
      <Meta />
      <meta http-equiv="refresh" content="0;url=https://docs.google.com/document/d/1VfUZ-9k-3u2MZ1FgSnrWInZnUUnvgUtI55DzJy9lBKU/edit" />
    </Layout>
  )
}
